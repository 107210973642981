import "./ourWork.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import { Swiper, SwiperSlide } from "swiper/react";
import Container from "react-bootstrap/Container";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

const card2 = [
  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/1-scaled-_1_.webp",
    title: "Athens Moving Experts",
    to: "ame",
  },
  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/IMG_2911-1024x768-1.webp",
    title: "Youngsville",
    to: "ygun",
  },

  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/Screenshot-93.webp",
    title: "Takle",
    to: "takle",
  },
  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/6.webp",
    title: "Al Baraka",
    to: "baraka",
  },
  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/7-scaled-1.webp",
    title: "See More",
    to: "see",
  },

  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/biddi1-scaled-1.webp",
    title: "Biddi Cars",
    to: "biddi",
  },
  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/zeal1-scaled-1.webp",
    title: "Zeal Fitness",
    to: "zeal",
  },

  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/studly.webp",
    title: "Studly",
    to: "studly",
  },

  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/09/ame-web-app.jpeg",
    title: "AME Logistics app",
    to: "ameApp",
  },
  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/minaret1-scaled-1.webp",
    title: "The Minaret",
    to: "minaret",
  },
  {
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/10/mep1.webp",
    title: "Mi Tienda",
    to: "mi",
  },
];
export default function OurWork() {
  const navigate = useNavigate();

  const handleClick = (to) => {
    if (to.startsWith("http")) {
      window.open(to, "_blank");
    } else {
      navigate("/portfolio");
      setTimeout(() => {
        const element = document.getElementById(to);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  return (
    <section className="ourWork">
      <div className="overlaySlide"></div>

      <div className="container2">
        {/* <Container className="mx-auto" style={{ maxWidth: "90%" }}> */}
        <div className="top">
          <h1>Our Work</h1>

          <p>
            We optimize and accelerate the growth of exceptional products. Some
            of our clients include Athens, KMS and many more.
          </p>
        </div>
        <div className="Carousel">
          <Swiper
            effect={"coverflow"}
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={-2}
            loop={true}
            autoplay={{
              delay: 2000, // يتغير كل 2.5 ثانية
              disableOnInteraction: false, // يستمر التبديل التلقائي حتى لو تفاعل المستخدم
              pauseOnMouseEnter: true,
            }}
            coverflowEffect={{
              rotate: -15,
              stretch: -80,
              depth: 300,
              modifier: 0.8,
              slideShadows: false,
            }}
            modules={[EffectCoverflow, Pagination, Autoplay]}
            className="mySwiper"
            allowTouchMove={true} // إضافة هذه الخاصية لمنع السحب اليدوي
            slideToClickedSlide={true} // الانتقال إلى الشريحة المنقور عليها
            slidesPerGroup={1} // التحرك بمقدار شريحة واحدة في كل مرة
            speed={300} // سرعة الانتقال بين الشرائح
          >
            {card2.map((item, index) => {
              return (
                <div key={index}>
                  <SwiperSlide className="card">
                    <div onClick={() => handleClick(item.to)}>
                      <img className="imgs" src={item.img} alt="photo" />
                      <h2>{item.title}</h2>
                      <p>Development</p>
                      <div className="icon">
                        <div className="lin"></div>
                        <span>&gt;</span>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
        {/* </Container> */}
      </div>
    </section>
  );
}
